/* ------------ basic setting ------------ */
body {
  font-family: arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
h1 {
  font-size: 1.802em;
}
h2 {
  font-size: 1.602em;
}
h3 {
  font-size: 1.424em;
}
h4 {
  font-size: 1.266em;
}
h5 {
  font-size: 1.125em;
}
h6 {
  font-size: 1em;
}

/* ------------ link color ------------ */
a,
a:hover {
  color: #1d78dc;
  text-decoration: none;
}
a:link {
  color: #000088;
  text-decoration: underline;
}
a:visited {
  color: #696969;
  text-decoration: underline;
}

body {
  color: #333333;
  background-color: #f5f5f5;
  margin: 0px;
  padding: 0px;
  font-size: 12px;
}

p {
  margin: 0.2rem 0 1rem;
}

table {
  width: 100%;
  margin: 0;
  padding: 0;
}

figure {
  margin: 0 auto;
  text-align: center;
}

figcaption {
  font-size: 0.9em;
}
img {
  border: 0;
  display: inline-block;
  vertical-align: middle;
}

ul {
  list-style: square url(images/indent.gif) outside;
  text-align: left;
  padding: 0 0 0 25px;
}

li {
  margin-bottom: 0.25em;
  line-height: 20px;
}

hr {
  width: 98%;
  height: 1px;
  border-bottom: 1px dotted #cccccc;
  color: #ffffff;
  background-color: inherit;
}

/* ------------ Layout Setting ------------ */
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.clearfix > .left {
  float: left;
}
.clearfix > .right {
  float: right;
}

.hidden {
  display: none;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.font-weight-bold {
  font-weight: bold;
}
.font-italic {
  font-style: italic;
}

/* ------------ main wrapper ------------ */
#root {
  min-width: 900px;
}
#page {
  margin: 0 auto;
  width: 900px;
}
#mid {
  padding-right: 10px;
  padding-left: 10px;
}
/* ------------ header ------------ */
#header {
  background-image: url(images/header_back.jpg);
  background-repeat: repeat-x;
}
#header .left {
  background-image: url(images/header_logo.jpg);
  background-position: left top;
  background-repeat: no-repeat;
  height: 124px;
  width: 332px;
}
#header .left a {
  display: block;
  width: 100%;
  height: 100%;
}
#header .right {
  margin: 5px 10px;
  text-align: right;
}
#header a:visited {
  color: #000;
}
#header .right .navi .home {
  float: left;
  margin: 10px 5px;
  background-image: url(images/icon_home.gif);
  background-repeat: no-repeat;
  text-indent: 18px;
  line-height: 15px;
}
#header .right .navi .contact {
  float: left;
  margin: 10px 5px;
  background-image: url(images/icon_contact.gif);
  background-position: 0 1px;
  background-repeat: no-repeat;
  text-indent: 18px;
  line-height: 15px;
}
#header .right .navi .language {
  float: right;
}
#header .right .navi .language img {
  margin: 0 5px;
}

/* ------------ footer ------------ */
#footer {
  margin-top: 30px;
  border-top: 1px solid #000;
  padding: 15px;
  text-align: center;
}

/* ------------ main part -------------*/
#main {
  margin: 15px 10px;
}

.mainContent h1 {
  background-color: #006699;
  color: #fff;
  padding: 0.3em;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}
.mainContent h1::before {
  content: "▮";
  margin: 0 13px 0 8px;
}
.mainContent .middle img {
  margin: 0 5px;
}
.mainContent p > a {
  margin: 0 3px;
}

.mainContent .clearfix > .left,
.mainContent .clearfix > .right {
  width: calc(50% - 10px);
  margin: 5px;
}

.BMIPaper .capture img {
  margin: 15px;
}

.BMIOriginalContents table {
  width: calc(100% - 10px);
  margin: 5px;
  padding: 0;
  border: 1px solid silver;
  border-collapse: collapse;
}
.BMIOriginalContents th {
  border: 1px solid silver;
  padding: 0.4em;
  background-color: silver;
  font-size: 1.266em;
}
.BMIOriginalContents th::before {
  margin: 0 5px;
  content: "▼";
}
.BMIOriginalContents td {
  border: 1px solid silver;
  padding: 1em;
  vertical-align: middle;
  line-height: 150%;
}
.BMIOriginalContents .title {
  color: #000088;
  font-weight: bold;
}
.BMIOriginalContents .description {
  margin: 3px 0 5px;
}
.BMIOriginalContents .author {
  margin: 5px;
  font-weight: bold;
}
.BMIOriginalContents .link {
  text-align: right;
}

.BMIPressRelease {
  list-style-type: none;
  list-style-image: none;
  padding: 0;
}
.BMIPressRelease > li {
  border-left: 20px solid #165ead;
  border-bottom: 1px solid #165ead;
  padding: 5px 10px;
  margin: 0 0 10px 0;
}
.BMIPressRelease .date {
  float: left;
  width: 100px;
  font-size: 1.125em;
}
.BMIPressRelease .body {
  float: right;
  width: calc(100% - 100px);
}
.BMIPressRelease .title {
  font-size: 1.125em;
  margin: 0 0 5px 0;
}
.BMIPressRelease .publisher {
  text-align: right;
}

.BMIResearchSites .usersmanual {
  text-align: right;
  font-size: 1.125em;
}
.BMIResearchSites .searchby {
  width: 420px;
}
.BMIResearchSites .searchby .title {
  margin: 15px 0;
  font-weight: bold;
  font-size: 1.266em;
}
.BMIResearchSites .searchby .title::before {
  content: "▼";
  margin-right: 5px;
}

.BMIResearchSites .location {
  float: left;
  margin: 10px 5px;
}
.BMIResearchSites .interest {
  float: right;
  margin: 10px 5px;
}
.BMIResearchSites .researcher {
  margin: 15px auto;
}

.BMIResearchSitesLocation .worldmap {
  margin: 20px 0;
  text-align: center;
}
.BMIResearchSitesLocationModal .regionMap {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
}
.BMIResearchSitesLocationModal .regionMap .map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
}
.BMIResearchSitesLocationModal .panel {
  position: fixed;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 5px 10px;
  box-shadow: 3px 3px 8px 0 #333333;
}
.BMIResearchSitesLocationModal .panel.siteList {
  top: 10px;
  right: 10px;
}
.BMIResearchSitesLocationModal .panel.siteList.show {
  bottom: 10px;
  min-height: 150px;
}
.BMIResearchSitesLocationModal .panel.siteList .close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.125em;
}
.BMIResearchSitesLocationModal .panel.back {
  top: 10px;
  left: 10px;
}
.BMIResearchSitesLocationModal .button {
  cursor: pointer;
  padding: 3px;
}
.BMIResearchSitesLocationModal .panel.siteList .list {
  margin: 20px 0;
  width: 300px;
  height: calc(100% - 35px);
  overflow: auto;
}
.BMIResearchSitesLocationModal .panel.siteList .list > ul {
  list-style: none;
  padding-left: 0px;
}
.BMIResearchSitesLocationModal .panel.siteList .list > ul > li > ul {
  list-style: circle;
}
.BMIResearchSitesLocationModal .panel.siteList .list .country {
  font-weight: bold;
  font-size: 1.266em;
  margin: 10px 0 5px;
}
.BMIResearchSitesLocationModal .panel.siteDetail {
  width: 700px;
  min-height: 400px;
  height: calc(100% - 200px);
  z-index: 120;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin: 0 auto;
  transform: translate(-50%, -50%);
}
.BMIResearchSitesLocationModal .panel.siteDetail .close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-weight: bold;
  font-size: 1.802em;
  cursor: pointer;
}
.BMIResearchSitesLocationModal .panel.siteDetail .detail {
  margin: 10px 20px;
  width: calc(100% - 40px);
  height: calc(100% - 90px);
  overflow: auto;
}
.BMIResearchSitesLocationModal .panel.siteDetail h1 {
  font-weight: bold;
  text-align: center;
}
.BMIResearchSitesLocationModal .panel.siteDetail .detail .laboratry {
  margin: 20px 0;
}
.BMIResearchSitesLocationModal .panel.siteDetail .detail .laboratry h2 {
  font-weight: bold;
}
.BMIResearchSitesLocationModal .panel.siteDetail .detail .laboratry .person {
  margin: 20px 0;
}
.BMIResearchSitesLocationModal .panel.siteDetail .detail .laboratry .person .name {
  font-weight: bold;
}
.BMIResearchSitesLocationModal .panel.siteDetail .detail .laboratry .link {
  font-weight: bold;
  margin: 20px 0;
}
.BMIResearchSitesLocationModal .panel.siteDetail .detail .laboratry .publications {
  margin: 20px 0;
}
.BMIResearchSitesLocationModal .panel.siteDetail .detail .laboratry .publications ul {
  list-style: circle;
  margin: 5px 0;
}
.BMIResearchSitesLocationModal .panel.siteDetail .detail .laboratry .interests {
  margin: 10px 0;
}

.BMIResearchSitesLocationModalOverlay {
  position: fixed;
  z-index: 110;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  opacity: 0.5;
}

.BMIResearchSitesResearcher .navi {
  font-size: 1.125em;
}
.BMIResearchSitesResearcher table {
  width: calc(100% - 10px);
  margin: 15px 5px;
  padding: 0;
  border: 1px solid silver;
  border-collapse: collapse;
}
.BMIResearchSitesResearcher th {
  text-align: left;
  font-size: 1.125em;
}
.BMIResearchSitesResearcher th,
.BMIResearchSitesResearcher td {
  border: 1px solid silver;
  padding: 5px 10px;
}
.BMIResearchSitesResearcher .center {
  text-align: center;
}

.BMIResearchSitesInterest .interest {
  float: left;
  width: 25%;
}
.BMIResearchSitesInterest .interest .title {
  font-weight: bold;
  font-size: 1.266em;
  margin: 10px 0;
}
.BMIResearchSitesInterest .interest .subTitle {
  font-size: 1.125em;
  margin: 10px 0;
  font-style: italic;
}
.BMIResearchSitesInterest .interest .icon {
  margin: 0 0 5px;
}

.BMIMaterials p {
    margin: 10px;
}
.BMIMaterials .navi {
  margin: 10px;
  font-size: 1.266em;
}
.BMIMaterials .navi::before {
  content: "▶";
  color: #549ce9;
  font-weight: bold;
  margin-right: 5px;
}
.BMIMaterials .back {
  margin-left: 10px;
  font-size: 1.266em;
  text-align: right;
}
.BMIMaterials .back::before {
  content: "«";
  color: #549ce9;
  font-weight: bold;
  margin-right: 5px;
}
.BMIMaterials table {
  margin: 15px 5px;
  padding: 0;
  border: 1px solid silver;
  border-collapse: collapse;
}
.BMIMaterials th,
.BMIMaterials td {
  border: 1px solid silver;
  padding: 5px 10px;
}
.BMIMaterials th {
  color: #ffffff;
  background-color: #549ce9;
  font-size: 1.266em;
}
.BMIMaterials .treePanel {
    float: left;
    width: 290px;
    height: 500px;
    overflow: auto;
    border: 1px solid silver;
}
.BMIMaterials .picturePanel {
    float: right;
    width: calc(100% - 300px);
    text-align: center;
}
.BMIMaterials .picturePanel .index {
    font-size: 1.266em;
    text-align: left;
}
.BMIMaterials .picturePanel .index .node {
    font-weight: bold;
}
.BMIMaterials .picturePanel .nopictures {
  margin: 40px 0;
  font-size: 1.266em;
}
.BMIMaterials .picturePanel .picture {
    display: inline-block;
    margin: 10px;
    width: 270px;
    height: 200px;
    vertical-align: middle;
}

.BMIColumn.clearfix > .left {
  width: 130px !important;
}
.BMIColumn.clearfix > .right {
  width: calc(100% - 150px) !important;
}
.BMIColumn .title {
  margin: 20px 0;
  font-size: 1.266em;
  font-weight: bold;
}
.BMIColumn .title .note {
  margin-left: 10px;
  font-size: 12px;
  font-weight: normal;
}
.BMIColumn .link {
  text-align: right;
  margin-right: 5px;
}
.BMIColumn .date {
  text-align: right;
  margin-top: 3em;
}

.BMICredits table {
  border: none;
}
.BMICredits td {
  border: none;
  padding: 0;
}
